<template>
  <b-overlay
    :show="$apollo.loading"
    rounded="sm"
    spinner-variant="primary"
  >
    <!-- search input -->
    <div class="custom-search d-flex justify-content-start">
      <b-form-group
        class="form-inline"
        label="Search"
        label-size="sm"
      >
        <b-form-input
          v-model="searchTerm"
          class="d-inline-block mr-1"
          placeholder="Search growth matrix"
          type="text"
        />
        <b-button
          v-b-modal.progressStageModal
          variant="primary"
        >
          <feather-icon icon="PlusIcon" />
          Add Growth Matrix
        </b-button>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
      :rows="rows"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!--Column : Stage Action -->
        <span v-if="props.column.field === 'startups'">
          <b-button
            :to="{name: 'progress-startups', params: { pid: $route.params.id,
                                                       id: props.row.id }}"
            size="sm"
            variant="outline-primary"
          >View Startups</b-button>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :per-page="pageLength"
              :total-rows="props.total"
              :value="1"
              align="right"
              class="mt-1 mb-0"
              first-number
              last-number
              next-class="next-item"
              prev-class="prev-item"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <b-modal
      id="progressStageModal"
      ok-only
      ok-title="Save"
      size="lg"
      title="Add Growth Matrix"
      @ok="addAssignment"
    >
      <b-row>
        <b-col md="5">
          <b-form-group
            label="Title"
            label-size="sm"
          >
            <b-form-input
              v-model="reviewTitle"
              placeholder="Review Title"
              required
              type="text"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Type"
            label-size="sm"
          >
            <b-form-input
              v-model="reviewType"
              placeholder="Review Type"
              required
              type="text"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="Operation"
            label-size="sm"
          >
            <treeselect
              v-model="selectedOperation"
              :normalizer="normalizer"
              :options="operations"
              placeholder="Select Phase, Subphase or Activities"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <hr>
      <form-builder
        v-model="applicationForm"
      />
    </b-modal>
  </b-overlay>
</template>
<script>

import {
  BButton, BCol, BFormGroup, BFormInput, BFormSelect, BPagination, BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import gql from 'graphql-tag'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FormBuilder from '@/views/incubator-portal/programs/components/FormBuilder2.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,

    VueGoodTable,
    BPagination,
    BFormSelect,
    // vSelect,
    Treeselect,
    FormBuilder,
  },
  data() {
    return {
      searchTerm: '',
      applicationForm: null,
      selectedRow: 0,
      pageLength: 5,
      filter: {
        status: 'Current',
      },
      columns: [
        {
          label: 'Review',
          field: 'title',
          width: '30%',
        },
        {
          label: 'Operation',
          field: 'operation_title',
        },
        {
          label: 'Type',
          field: 'type',
        },
        {
          label: 'Status',
          field: 'status',
          tdClass: 'text-center',
        },
        {
          label: 'Evaluation',
          field: 'review_status',
          tdClass: 'text-center',
        },
      ],
      operations: [],
      rows: [],
      progressStageTitle: null,
      selectedOperation: [],
    }
  },
  methods: {
    normalizer(node) {
      return {
        id: node.id,
        label: node.title,
        children: node.programs_operationstables,
      }
    },
    addAssignment(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.basicInfo.validate()
        .then(success => {
          if (success) {
            this.$apollo.mutate(
              {
                mutation: gql`mutation($data: [programs_operationstable_insert_input!]!) {
              insert_programs_progressstagetable_one(object: {title: ${this.progressStageTitle}, programs_operationstables: {data: $data, on_conflict: {constraint: programs_operationstable_pkey, update_columns: progress_staage_id}}}) {
                id
              }
            }`,
                variables: {
                  data: this.selectedOperation.map(e => ({
                    id: e,
                    program_id: this.$route.params.id,
                  })),
                },
                update: (store, { data: { insert_programs_progressstagetable_one } }) => {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: insert_programs_progressstagetable_one.id ? 'Progress stage created successfully' : 'Failed to create progress stage',
                      icon: insert_programs_progressstagetable_one.id ? 'CheckIcon' : 'XIcon',
                      variant: insert_programs_progressstagetable_one.id ? 'success' : 'danger',
                    },
                  })
                  this.$apollo.queries.rows.refetch()
                },
              },
            )
            this.progressStageTitle = null
            this.selectedOperation = null
            this.$nextTick(() => {
              this.$bvModal.hide('progressStageModal')
            })
            return true
          }
          return false
        })
    },
  },
  apollo: {
    rows: {
      query() {
        return gql`
        {
          programs_progressstagetable(where: {programs_operationstables: {program_id: {_eq: ${this.$route.params.id}}}}) {
            id
            title
            programs_operationstables {
                 title
              }
            }
          }`
      },
      update: data => data.programs_progressstagetable,
    },
    operations: {
      query() {
        return gql`{
            programs_operationstable(where: {program_id: {_eq: ${this.$route.params.id}}, operations_type: {_eq: "Phase"}}) {
                  id
                  title
                  programs_operationstables(where: {operations_type: {_eq: "SubPhase"}}) {
                    id
                    title
                    programs_operationstables {
                      id
                      title
                    }
                  }
                }
              }`
      },
      update: data => data.programs_operationstable,
    },
  },
}
</script>
