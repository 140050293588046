<template>
  <div>
    <b-form
      :style="{height: trHeight}"
      class="repeater-form"
    >
      <transition-group
        name="list"
        mode="out-in"
      >
        <b-row
          v-for="(section, s) in sections"
          :key="s"
          class="mb-2"
        >
          <b-col
            cols="12"
          >
            <transition-group
              tag="div"
              name="list"
            >
              <!-- Row Loop -->
              <b-row
                v-for="(item, index) in section.items"
                :id="`row`+index"
                :key="index"
                ref="row"
                class="mx-0 w-100 rounded mb-1 border py-1 pr-1 bg-light"
              >
                <!-- Item Name -->
                <b-col cols="6">
                  <b-form-group
                    :label="`Question ${index+1}`"
                    :label-for="`question-${s}-${index}`"
                  >
                    <b-form-input
                      :id="`question-${s}-${index}`"
                      v-model="item.question"
                      placeholder="Question"
                      required
                      type="text"
                      @change="notifyParent"
                    />
                    <b-form-tags
                      v-if="item.input_type === 'Dropdown' || item.input_type === 'Multiselect'"
                      v-model="item.options"
                      class="mt-50"
                      placeholder="Add Option"
                      :input-id="`options-${s}-${index}`"
                      :tag-pills="item.input_type === 'Dropdown'"
                      remove-on-delete
                      @input="notifyParent"
                    />
                  </b-form-group>
                </b-col>

                <!-- Answer -->
                <b-col>
                  <b-form-group
                    :label-for="`data_type-${s}-${index}`"
                    label="Data Type"
                  >
                    <v-select
                      :id="`data_type-${s}-${index}`"
                      v-model="item.input_type"
                      :options="['Short text',
                                 'Long text',
                                 'Number',
                                 'Dropdown',
                                 'Multiselect',
                                 'Document',]"
                      append-to-body
                      class="input_type"
                      label="Data type"
                      placeholder="Select from list"
                      @change="notifyParent"
                    />
                    <small
                      v-if="item.input_type === 'Document'"
                      class="d-flex align-items-center pt-25 text-secondary "
                    >
                      <feather-icon
                        class="mx-25"
                        icon="InfoIcon"
                      />
                      Max file size: 5MB</small>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="auto"
                >
                  <b-form-group
                    :label-for="`required-${s}-${index}`"
                    label="Required?"
                  >
                    <b-form-checkbox
                      :id="`required-${s}-${index}`"
                      v-model="item.is_required"
                      checked="true"
                      class="mt-50"
                      inline
                      switch
                    />
                  </b-form-group>
                </b-col>
                <!-- Remove Button -->
                <b-col
                  class="mb-50"
                  cols="auto"
                >
                  <b-button
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    :disabled="section.items.length < 2"
                    class="mt-0 mt-md-2"
                    variant="outline-danger"
                    @click="section.items.splice(index, 1)"
                  >
                    <feather-icon
                      icon="XIcon"
                    />
                  </b-button>
                </b-col>
              </b-row>
              <b-row :key="`button`">
                <b-col>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    class="ml-25"
                    variant="outline-primary"
                    @click="section.items.push({
                      question: '',
                      options: [],
                      input_type: '',
                      is_required: true
                    })"
                  >
                    <feather-icon
                      class="mr-25"
                      icon="PlusIcon"
                    />
                    <span>Add Question</span>
                  </b-button>
                </b-col>
              </b-row>
            </transition-group>

          </b-col>
        </b-row>
      </transition-group>
    </b-form>
  </div>
</template>

<script>
import {
  BButton, BCol, BForm, BFormCheckbox, BFormGroup, BFormInput, BFormTags, BRow,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormTags,
    BFormCheckbox,
    vSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  props: ['inheritedSections'],
  data() {
    return {
      sections: this.inheritedSections?.length ? this.sectionObject(this.inheritedSections, 'section') : [
        {
          title: '',
          items: [{
            question: '',
            options: [],
            input_type: '',
            is_required: true,
          }],
        },
      ],
    }
  },
  computed: {
    linearizeFormBuilder() {
      const obj = []
      this.sections.forEach(e => e.items.forEach(f => {
        obj.push({
          section: e.title || null,
          question: f.question || null,
          options: JSON.stringify(f.options) || null,
          input_type: f.input_type || 'Short text',
          is_required: f.is_required || true,
        })
        if (f.id) {
          obj[obj.length - 1].id = f.id
        }
      }))
      return obj
    },
  },
  methods: {
    sectionObject(array, key) {
      if (array === undefined) return false
      const arr = Array.from(array)
        .reduce((hash, obj) => {
          if (obj[key] === undefined) return hash
          return Object.assign(hash, { [obj[key]]: (hash[obj[key]] || []).concat(obj) })
        }, {})
      const obj = []
      Object.entries(arr)
        .forEach(([k, v]) => {
          v.map(a => {
            // eslint-disable-next-line no-param-reassign
            if (typeof a.options !== 'object') a.options = JSON.parse(a.options)
            return a
          })
          obj.push({
            title: k,
            items: v,
          })
        })
      return obj
    },
    notifyParent() {
      this.$emit('input', this.linearizeFormBuilder)
    },
  },
}
</script>

<style lang="scss">
.list-move, /* apply transition to moving elements */
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.list-leave-active {
  position: absolute;
}

.vs__dropdown-menu {
  z-index: 1051 !important;
}

.repeater-form {
  overflow: hidden;
  transition: .35s height;
}

[dir="ltr"] .b-form-tag {
  margin-right: 0.5rem;
}
</style>
